<template>
  <form @submit.prevent="submit">
    <b-field label="Вид документа">
      <b-radio-button
        v-model="isPassport"
        native-value="0"
        type="is-primary"
        expanded
      >
        <b-icon icon="id-card" size="is-medium"></b-icon>
        <span>Вид на жительство</span>
      </b-radio-button>

      <b-radio-button
        v-model="isPassport"
        native-value="1"
        type="is-primary"
        expanded
      >
        <b-icon icon="passport" size="is-medium"></b-icon>
        <span>Паспорт</span>
      </b-radio-button>
    </b-field>
    <b-field
      :label="textSeries"
      :type="{
        'is-danger': $v.passportSeries.$error,
        'is-success': !$v.passportSeries.$invalid && $v.passportSeries.$dirty,
      }"
      :message="{ 'Некорректно заполено поле': $v.passportSeries.$error }"
    >
      <b-input
        placeholder=""
        v-model.trim="passportSeries"
        @blur="onTouchField('passportSeries')"
      ></b-input>
    </b-field>
    <b-field
      :label="textNumber"
      :type="{
        'is-danger': $v.passportNumber.$error,
        'is-success': !$v.passportNumber.$invalid && $v.passportNumber.$dirty,
      }"
      :message="{ 'Некорректно заполено поле': $v.passportNumber.$error }"
    >
      <b-input
        placeholder=""
        v-model.trim="passportNumber"
        @blur="onTouchField('passportNumber')"
      ></b-input>
    </b-field>
    <div class="button-wrapper">
      <b-button
        type="is-info"
        expanded
        native-type="submit"
        :disabled="submitDisabled"
        >Поиск</b-button
      >
    </div>
  </form>
</template>

<script>
import { minLength, maxLength, required } from 'vuelidate/lib/validators';
import { onTouchField } from '@/utils/helpers';

export default {
  name: 'FormNonResident',
  data() {
    return {
      passportSeries: '',
      passportNumber: '',
      isPassport: '1',
      textSeries: 'Серия паспорта',
      textNumber: 'Номер паспорта',
      onTouchField,
    };
  },
  computed: {
    submitDisabled() {
      return !(this.passportSeries.length && this.passportNumber.length);
    },
  },
  watch: {
    isPassport(val) {
      this.textSeries = Number(val) ? 'Серия паспорта' : 'Серия документа';
      this.textNumber = Number(val) ? 'Номер паспорта' : 'Номер документа';
    },
  },
  methods: {
    submit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      const clientData = {
        passportSeries: this.passportSeries,
        passportNumber: this.passportNumber,
      };

      this.$store.dispatch('search/searching', clientData);
    },
  },
  validations() {
    const defaultRules = {
      passportSeries: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(6),
      },
      passportNumber: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(10),
      },
    };

    return defaultRules;
  },
};
</script>
