<template>
  <header>
    <b-navbar>
      <template #brand>
        <div class="logo">
          <!-- <router-link to="/workspace"><span>i</span> - ASSISTANT</router-link> -->
          <a href="/workspace">I-ASSISTANT</a>
        </div>
      </template>
      <template #end>
        <div class="polises-utils">
          <b-field grouped group-multiline>
            <div class="control" v-if="Number(user.polisesExpired)">
              <router-link
                :to="{ name: 'PolisesUtils', params: { tab: 'expired' } }"
              >
                <b-tooltip
                  label="Просроченные полисы"
                  type="is-danger is-light"
                  position="is-bottom"
                >
                  <b-taglist attached>
                    <b-tag type="is-danger is-light">
                      <b-icon icon="file-medical" size="is-medium"></b-icon>
                    </b-tag>
                    <b-tag type="is-danger" class="tag-bold">
                      {{ user.polisesExpired }}
                    </b-tag>
                  </b-taglist>
                </b-tooltip>
              </router-link>
            </div>

            <div class="control" v-if="Number(user.polisesExpires)">
              <router-link
                :to="{ name: 'PolisesUtils', params: { tab: 'expires' } }"
              >
                <b-tooltip
                  label="Заканчивающиеся полисы"
                  type="is-warning is-light"
                  position="is-bottom"
                >
                  <b-taglist attached>
                    <b-tag type="is-warning is-light">
                      <b-icon icon="file-medical" size="is-medium"></b-icon>
                    </b-tag>
                    <b-tag type="is-warning" class="tag-bold">
                      {{ user.polisesExpires }}
                    </b-tag>
                  </b-taglist>
                </b-tooltip>
              </router-link>
            </div>

            <div class="control" v-if="Number(user.polisesToRenew)">
              <router-link
                :to="{ name: 'PolisesUtils', params: { tab: 'to-renew' } }"
              >
                <b-tooltip
                  label="Полисы к продлению"
                  type="is-success is-light"
                  position="is-bottom"
                >
                  <b-taglist attached>
                    <b-tag type="is-success is-light">
                      <b-icon icon="file-medical" size="is-medium"></b-icon>
                    </b-tag>
                    <b-tag type="is-success" class="tag-bold">
                      {{ user.polisesToRenew }}
                    </b-tag>
                  </b-taglist>
                </b-tooltip>
              </router-link>
            </div>

            <div class="control" v-if="Number(user.birthdaysToday)">
              <router-link
                :to="{ name: 'PolisesUtils', params: { tab: 'birthday' } }"
              >
                <b-tooltip
                  label="Дни рождения клиентов"
                  type="is-info is-light"
                  position="is-bottom"
                >
                  <b-taglist attached>
                    <b-tag type="is-info is-light">
                      <b-icon icon="birthday-cake" size="is-medium"></b-icon>
                    </b-tag>
                    <b-tag type="is-info" class="tag-bold">
                      {{ user.birthdaysToday }}
                    </b-tag>
                  </b-taglist>
                </b-tooltip>
              </router-link>
            </div>
          </b-field>
        </div>
        <b-navbar-item href="/workspace" v-if="showWorkspace"
          >Поиск</b-navbar-item
        >
        <b-navbar-dropdown label="Новый клиент" right boxed>
          <b-navbar-item href="/new-client/physical"
            >Физическое лицо</b-navbar-item
          >
          <b-navbar-item href="/new-client/entity"
            >Юридическое лицо</b-navbar-item
          >
          <b-navbar-item href="/new-client/non-resident"
            >Нерезидент РФ</b-navbar-item
          >
        </b-navbar-dropdown>
        <b-navbar-dropdown label="Профиль" right boxed>
          <b-navbar-item href="/user" v-if="!superAdmin">Профиль</b-navbar-item>
          <b-navbar-item href="/admin" v-if="superAdmin"
            >Админ панель</b-navbar-item
          >
          <b-navbar-item href="/statistic" v-if="!superAdmin"
            >Статистика</b-navbar-item
          >
          <b-navbar-item href="#" @click.prevent="logout">Выйти</b-navbar-item>
        </b-navbar-dropdown>
      </template>
    </b-navbar>
  </header>
</template>

<script>
export default {
  name: 'Header',
  computed: {
    user() {
      return this.$store.state.user;
    },
    showWorkspace() {
      return this.$route.name !== 'Workspace';
    },
    role() {
      return this.user !== undefined ? this.user.role : '';
    },
    superAdmin() {
      return this.role === 1;
    },
  },
  methods: {
    async logout() {
      this.$store.commit('toggleLoader', true);
      await this.$store.dispatch('logout');
      this.$store.commit('toggleLoader', false);
      this.$router.push('/login');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

header {
  grid-area: header;
  position: sticky;
  top: 0;
  z-index: 9;
  width: 100%;
  -webkit-box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0px 0 1px rgb(10 10 10 / 2%);
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0px 0 1px rgb(10 10 10 / 2%);

  .navbar {
    padding: 0 20px;
  }

  .logo {
    font-weight: lighter;
    font-size: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 1px;
    color: #496ca2;

    span {
      font-weight: bold;
      font-size: 22px;
    }
  }

  .polises-utils {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 50px;

    .tag-bold {
      font-weight: bold;
    }
  }
}
</style>
