<template>
  <form @submit.prevent="submit">
    <b-field
      label="Телефон"
      :type="{
        'is-danger': $v.phone.$error,
        'is-success': !$v.phone.$invalid && $v.phone.$dirty,
      }"
      :message="{ 'Телефон в формате +7(900)000-00-00': $v.phone.$error }"
    >
      <IMaskComponent
        placeholder="+7(900)000-00-00"
        maxlength="16"
        inputmode="numeric"
        class="input"
        :class="{
          'is-danger': $v.phone.$error,
          'is-success': !$v.phone.$invalid && $v.phone.$dirty,
        }"
        :mask="'+{7}({9}00)000-00-00'"
        v-model.trim="phone"
        @blur="onTouchField('phone')"
      />
    </b-field>
    <b-field
      label="Фамилия"
      :type="{
        'is-danger': $v.lastName.$error,
        'is-success':
          !$v.lastName.$invalid &&
          $v.lastName.$dirty &&
          $v.lastName.$model.length,
      }"
      :message="{ 'Некорректно указана фамилия': $v.lastName.$error }"
    >
      <b-autocomplete
        placeholder="Фамилия"
        :data="lastNameData"
        :keep-first="false"
        :open-on-focus="false"
        :clearable="true"
        :loading="isFetching"
        v-model.trim="lastName"
        @typing="getDadata($event, 'lastName')"
        @select="setGender"
        @blur="onTouchField('lastName')"
      >
      </b-autocomplete>
    </b-field>
    <b-field
      label="Имя"
      :type="{
        'is-danger': $v.firstName.$error,
        'is-success':
          !$v.firstName.$invalid &&
          $v.firstName.$dirty &&
          $v.firstName.$model.length,
      }"
      :message="{ 'Некорректно указано имя': $v.firstName.$error }"
    >
      <b-autocomplete
        placeholder="Имя"
        :data="firstNameData"
        :keep-first="false"
        :open-on-focus="false"
        :clearable="true"
        :loading="isFetching"
        v-model.trim="firstName"
        @typing="getDadata($event, 'firstName')"
        @select="setGender"
        @blur="onTouchField('firstName')"
      >
      </b-autocomplete>
    </b-field>
    <b-field
      label="Отчество"
      :type="{
        'is-danger': $v.patronymic.$error,
        'is-success':
          !$v.patronymic.$invalid &&
          $v.patronymic.$dirty &&
          $v.patronymic.$model.length,
      }"
      :message="{ 'Некорректно указано отчество': $v.patronymic.$error }"
    >
      <b-autocomplete
        placeholder="Отчество"
        :data="patronymicData"
        :keep-first="false"
        :open-on-focus="false"
        :clearable="true"
        :loading="isFetching"
        v-model.trim="patronymic"
        @typing="getDadata($event, 'patronymic')"
        @select="setGender"
        @blur="onTouchField('patronymic')"
      >
      </b-autocomplete>
    </b-field>
    <b-field
      label="Серия и номер паспорта"
      :type="{
        'is-danger': $v.passport.$error,
        'is-success': !$v.passport.$invalid && $v.passport.$dirty,
      }"
      :message="{ 'Серия и номер паспорта 10 цифр': $v.passport.$error }"
    >
      <IMaskComponent
        placeholder="0000 000000"
        inputmode="numeric"
        class="input"
        :class="{
          'is-danger': $v.passport.$error,
          'is-success': !$v.passport.$invalid && $v.passport.$dirty,
        }"
        :mask="'0000 000000'"
        v-model.trim="passport"
        @blur="onTouchField('passport')"
      />
    </b-field>
    <b-field
      label="Серия полиса"
      :type="{
        'is-danger': $v.polisSeries.$error,
        'is-success': !$v.polisSeries.$invalid && $v.polisSeries.$dirty,
      }"
      :message="{ 'Некорректно заполено поле': $v.polisSeries.$error }"
    >
      <b-input
        placeholder="Серия полиса"
        v-model.trim="polisSeries"
        @blur="onTouchField('polisSeries')"
      ></b-input>
    </b-field>
    <b-field
      label="Номер полиса"
      :type="{
        'is-danger': $v.polisNumber.$error,
        'is-success': !$v.polisNumber.$invalid && $v.polisNumber.$dirty,
      }"
      :message="{ 'Некорректно заполено поле': $v.polisNumber.$error }"
    >
      <b-input
        placeholder="Номер полиса"
        v-model.trim="polisNumber"
        @blur="onTouchField('polisNumber')"
      ></b-input>
    </b-field>
    <b-field
      label="VIN код авто"
      :type="{
        'is-danger': $v.vin.$error,
        'is-success': !$v.vin.$invalid && $v.vin.$dirty,
      }"
      :message="{ 'VIN код авто 17 символов': $v.vin.$error }"
    >
      <IMaskComponent
        placeholder="VIN"
        inputmode="numeric"
        class="input"
        :class="{
          'is-danger': $v.vin.$error,
          'is-success': !$v.vin.$invalid && $v.vin.$dirty,
        }"
        :mask="'*****************'"
        :prepare="(str) => str.toUpperCase()"
        v-model.trim="vin"
        @blur="onTouchField('vin')"
      />
    </b-field>
    <b-field
      label="Гос номер авто"
      :type="{
        'is-danger': $v.carNumber.$error,
        'is-success': !$v.carNumber.$invalid && $v.carNumber.$dirty,
      }"
      :message="{
        'Гос номер должен состоять из кириллицы': !$v.carNumber.alphaNum,
        'Гос номер авто 8-9 символов': !$v.carNumber.minLength,
      }"
    >
      <b-input
        inputmode="numeric"
        placeholder="A000AA152"
        v-model.trim="carNumber"
        @blur="onTouchField('carNumber')"
      ></b-input>
    </b-field>
    <div class="button-wrapper">
      <b-button
        type="is-info"
        expanded
        native-type="submit"
        :disabled="submitDisabled"
        >Поиск</b-button
      >
    </div>
  </form>
</template>

<script>
import { IMaskComponent } from 'vue-imask';
import { minLength, maxLength, required } from 'vuelidate/lib/validators';
import { helpers } from 'vuelidate/lib/validators';
import { onTouchField } from '@/utils/helpers';
import DadataAPI from '@/api/dadata';
import debounce from '@/utils/debounce';

const alphaNum = helpers.regex('alphaNum', /^[а-яА-Я0-9]*$/);

export default {
  name: 'FormPhysical',
  data() {
    return {
      lastNameData: [],
      firstNameData: [],
      patronymicData: [],
      isFetching: false,
      phone: '',
      lastName: '',
      firstName: '',
      patronymic: '',
      gender: '', // helper
      passport: '', // helper
      passportSeries: '',
      passportNumber: '',
      polisSeries: '',
      polisNumber: '',
      type: '',
      vin: '',
      carNumber: '',
      onTouchField,
    };
  },
  computed: {
    requiredFIO() {
      return !!(
        this.lastName.length ||
        this.firstName.length ||
        this.patronymic.length
      );
    },
    submitDisabled() {
      return !(
        this.phone.length ||
        this.lastName.length ||
        this.firstName.length ||
        this.patronymic.length ||
        this.passport.length ||
        this.polisSeries.length ||
        this.polisNumber.length ||
        this.type.length ||
        this.vin.length ||
        this.carNumber.length
      );
    },
  },
  watch: {
    passport(val) {
      const passport = val.split(' ');
      this.passportSeries = passport[0];
      this.passportNumber = passport[1];
    },
  },
  methods: {
    setGender(option) {
      if (option !== null) {
        this.gender =
          option.data.gender !== 'UNKNOWN'
            ? option.data.gender === 'MALE'
              ? '0'
              : '1'
            : this.gender;
      }
    },
    getDadata: debounce(async function (value, field) {
      if (!value.length) {
        this[field + 'Data'] = [];
        return;
      }

      this.isFetching = true;

      let data = await DadataAPI.getFIO({
        value,
        type: field,
        gender: this.gender,
      });

      this[field + 'Data'] = [];

      if (data.length) {
        data.forEach((item) => this[field + 'Data'].push(Object.freeze(item)));
      }

      this.isFetching = false;
    }, 500),
    submit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      const clientData = {
        phone: this.phone,
        lastName: this.lastName,
        firstName: this.firstName,
        patronymic: this.patronymic,
        passportSeries: this.passportSeries,
        passportNumber: this.passportNumber,
        polisSeries: this.polisSeries,
        polisNumber: this.polisNumber,
        type: this.type,
        vin: this.vin,
        carNumber: this.carNumber.toUpperCase(),
      };

      this.$store.dispatch('search/searching', clientData);
    },
  },
  validations() {
    const defaultRules = {
      lastName: {
        minLength: minLength(2),
        maxLength: maxLength(40),
      },
      firstName: {
        minLength: minLength(2),
        maxLength: maxLength(40),
      },
      patronymic: {
        minLength: minLength(2),
        maxLength: maxLength(40),
      },
      phone: {
        minLength: minLength(16),
        maxLength: maxLength(16),
      },
      passport: {
        minLength: minLength(11),
        maxLength: maxLength(11),
      },
      polisSeries: {
        minLength: minLength(2),
      },
      polisNumber: {
        minLength: minLength(4),
        maxLength: maxLength(20),
      },
      vin: {
        minLength: minLength(17),
        maxLength: maxLength(17),
      },
      carNumber: {
        alphaNum,
        minLength: minLength(8),
        maxLength: maxLength(9),
      },
    };

    if (this.requiredFIO) {
      defaultRules.lastName.required = required;
      defaultRules.firstName.required = required;
      defaultRules.patronymic.required = required;
    } else if (this.phone.length) {
      defaultRules.phone.required = required;
    } else if (this.passport.length) {
      defaultRules.passport.required = required;
    } else if (this.polisNumber.length) {
      defaultRules.polisNumber.required = required;
    } else if (this.vin.length) {
      defaultRules.vin.required = required;
    } else if (this.carNumber.length) {
      defaultRules.carNumber.required = required;
    }

    return defaultRules;
  },
  components: { IMaskComponent },
};
</script>
