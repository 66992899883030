<template>
  <div class="sidebar-search">
    <SearchForm />
    <!-- <div class="hide-or-show">
      <b-icon
        pack="fas"
        icon="exchange-alt"
        size="is-large"
        type="is-white"
      ></b-icon>
      <b-icon
        pack="fas"
        icon="angle-double-right"
        size="is-large"
        type="is-white"
      ></b-icon>
      <b-icon
        pack="fas"
        icon="angle-double-left"
        size="is-large"
        type="is-white"
      ></b-icon>
    </div> -->
  </div>
</template>

<script>
import SearchForm from '@/components/search/SearchForm';

export default {
  name: 'SidebarSearch',
  components: {
    SearchForm,
  },
};
</script>

<style lang="scss">
@import '~@/assets/scss/variables';

.sidebar-search {
  grid-area: sidebar;
  background-color: $sidebar;
  // background: linear-gradient(to top, #283e51, #0a2342);
  background: linear-gradient(to top, #204675, #15406c);
  color: $white;
  position: sticky;
  top: 0;
  height: 100vh;
  padding: 10px 0px;

  .hide-or-show {
    cursor: pointer;
    display: block;
    width: 100%;

    i {
      color: inherit;
      transition: all 0.3s;

      &:hover {
        color: #365287 !important;
      }
    }
  }
}
</style>
