<template>
  <form @submit.prevent="submit">
    <b-field
      label="Телефон"
      :type="{
        'is-danger': $v.phone.$error,
        'is-success': !$v.phone.$invalid && $v.phone.$dirty,
      }"
      :message="{ 'Телефон в формате +7(900)000-00-00': $v.phone.$error }"
    >
      <IMaskComponent
        placeholder="+7(900)000-00-00"
        maxlength="16"
        inputmode="numeric"
        class="input"
        :class="{
          'is-danger': $v.phone.$error,
          'is-success': !$v.phone.$invalid && $v.phone.$dirty,
        }"
        :mask="'+{7}({9}00)000-00-00'"
        v-model.trim="phone"
        @blur="onTouchField('phone')"
      />
    </b-field>
    <b-field
      label="ИНН"
      :type="{
        'is-danger': $v.inn.$error,
        'is-success': !$v.inn.$invalid && $v.inn.$dirty && $v.inn.$model.length,
      }"
      :message="{ 'Некорректно указан ИНН': $v.inn.$error }"
    >
      <b-input
        placeholder="Введите ИННН"
        maxlength="10"
        v-model.trim="inn"
        @blur="onTouchField('inn')"
      ></b-input>
    </b-field>
    <div class="button-wrapper">
      <b-button
        type="is-info"
        expanded
        native-type="submit"
        :disabled="submitDisabled"
        >Поиск</b-button
      >
    </div>
  </form>
</template>

<script>
import { IMaskComponent } from 'vue-imask';
import { minLength, maxLength, required } from 'vuelidate/lib/validators';
import { onTouchField } from '@/utils/helpers';

export default {
  name: 'FormEntity',
  data() {
    return {
      phone: '',
      inn: '',
      onTouchField,
    };
  },
  computed: {
    submitDisabled() {
      return !(this.phone.length || this.inn.length);
    },
  },
  methods: {
    submit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      const clientData = {
        phone: this.phone,
        inn: this.inn,
      };

      this.$store.dispatch('search/searching', clientData);
    },
  },
  validations() {
    const defaultRules = {
      inn: {
        maxLength: maxLength(15),
      },
      phone: {
        minLength: minLength(16),
        maxLength: maxLength(16),
      },
    };

    if (this.phone.length) {
      defaultRules.phone.required = required;
    } else if (this.inn.length) {
      defaultRules.inn.required = required;
    }

    return defaultRules;
  },
  components: { IMaskComponent },
};
</script>
