<template>
  <div class="form-wrapper">
    <h4 class="title is-4">Поиск анкеты</h4>
    <b-tabs class="tabs-search" position="is-centered" v-model="activeTab">
      <b-tab-item label="Физ лицо">
        <FormPhysical />
      </b-tab-item>

      <b-tab-item label="Юр лицо">
        <FormEntity />
      </b-tab-item>

      <b-tab-item label="Нерезидент">
        <FormNonResident />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import FormPhysical from '@/components/search/form/FormPhysical';
import FormEntity from '@/components/search/form/FormEntity';
import FormNonResident from '@/components/search/form/FormNonResident';

export default {
  name: 'SearchForm',
  data() {
    return {
      activeTab: 0,
    };
  },
  components: {
    FormPhysical,
    FormEntity,
    FormNonResident,
  },
};
</script>

<style lang="scss">
@import '~@/assets/scss/variables';

.form-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  h4 {
    color: $white;
    font-weight: normal;
  }

  .tabs-search {
    width: 100%;

    .tabs {
      li {
        a {
          font-size: 13px;
          color: #eee;
        }

        &.is-active {
          a {
            border-bottom-color: #337bb8;
            color: $white;
          }
        }
      }
    }

    .tab-content {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 10px;
    }
  }

  form {
    width: 100%;
    height: calc(100vh - 120px);
    overflow-y: hidden;
    padding: 0 20px 0;

    .radio {
      color: #363636;

      &.is-selected {
        color: $white;
      }

      &:hover {
        box-shadow: inset 0px 0px 20px 0px rgb(69 102 153 / 60%);
      }
    }

    &:hover {
      overflow-y: auto;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
      -moz-overflow-scrolling: touch;
      -ms-overflow-scrolling: touch;
      -o-overflow-scrolling: touch;
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: #337bb8;
    }

    label {
      color: $white;
      font-weight: normal;
    }

    .button-wrapper {
      margin-top: 30px;
    }
  }
}
</style>
